<template>
    <main class="w-100 py-25 page_bg_grey blank-aside-js min-h-100vh" :class = "(isOpenAside=='true')?'blank-aside':''">
        <section v-if="transactionReceiptData" class="success-section fs16">
            <div>
                <p class="mb-5 fs20">{{ $t("Invitation accepted") }}</p>
                <p class="mb-0">
                    <span class="font-weight-bold">{{ $t("Date") }}:</span> {{ transactionReceiptData.transDateTime | formatDate }}
                </p>
                <p class="mb-0">
                    <span class="font-weight-bold">{{ $t("Place") }}:</span> {{ transactionReceiptData.user_address }}
                </p>
                <p class="mb-0">
                    <span class="font-weight-bold">{{ $t("Agent") }}:</span> {{ transactionReceiptData.business_name }}
                </p>
            </div>

            <hr class="my-20">

            <div class="d-flex justify-content-between align-items-center mb-5">
                <h2 class="font-weight-bold">{{ $t("Invoice") }} {{ ' ' + transactionReceiptData.transId + ' ' }}</h2>
                <div class="hide-print">
                    <div class="d-flex align-items-center font-weight-bold pointer " @click="printReceipt">
                        <img src="/assets/img/print.svg" alt="" class="mx-5">
                        {{ $t("For printing") }}
                    </div>
                </div>
            </div>
            <div class="bg-white">
                <div class="d-flex font-weight-bold bd-b-1 py-5">
                    <div class="w-60 px-10">
                        {{ $t("Product") }}
                    </div>
                    <div class="w-15 text-center min-w-100px">
                        {{ $t("Quantity") }}
                    </div>
                    <div class="w-25 text-center min-w-100px">
                        {{ $t("Price") }}
                    </div>
                </div>
                <div class="d-flex bd-b-1 py-10">
                    <div class="w-60 px-10">
                        {{ transactionReceiptData.product }}
                    </div>
                    <div class="w-15 text-center min-w-100px">
                        1
                    </div>
                    <div class="w-25 text-center min-w-100px">
                        {{ transactionReceiptData.price }}
                    </div>
                </div>
                <div class="py-10 d-flex justify-content-end">
                    <div class="d-flex flex-wrap w-50">
                        <div class="w-50 px-2 mb-5">
                            {{ $t("Total") }}
                        </div>
                        <div class="w-50 px-2 mb-5 text-center">
                            {{ priceWithoutVAT }} ₪
                        </div>
                        <div class="w-50 px-2 mb-5">
                            {{ $t("VAT") }}
                        </div>
                        <div class="w-50 px-2 mb-5 text-center">
                            {{ priceVAT }} ₪
                        </div>
                        <div class="w-50 px-2 mb-5 fs16">
                            {{ $t("Total payment including VAT") }}
                        </div>
                        <div class="w-50 px-2 mb-5 fs16 font-weight-bold text-center">
                            {{ price }} ₪
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="w-100 text-center mt-20 hide-print">
            <v-btn
                elevation="1" 
                color="primary"
                @click="$router.go(-1)" 
            >   
                {{ $t("Back") }}
            </v-btn>
        </div>
    </main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import appConfig from '../../appConfig'
export default {
    name: 'CommunicationPaymentSuccess',
    mounted() {},
    computed: {
        ...mapState({
            transactions_receipts: state => state.payment.transactions_receipts,
            current_transaction_supplier_id: state => state.payment.current_transaction_supplier_id,
            current_transaction_product_id: state => state.payment.current_transaction_product_id,
            supplier_items: state => state.supplier.supplier_items,
            suppliers: state => state.supplier.suppliers,
            suppliers_products: state => state.supplier.suppliers_products,
            products: state => state.product.products
        }),
        transactionId() {
            return this.$route.params.id
        },
        transactionSupplierId() {
            return this.current_transaction_supplier_id ? this.current_transaction_supplier_id : null
        },
        transactionProductId() {
            return this.current_transaction_product_id ? this.current_transaction_product_id : null
        },
        transactionReceiptData() {
            let transactionReceiptData = null;
            if (this.transactions_receipts && this.transactionId in this.transactions_receipts) {
                transactionReceiptData = {...this.transactions_receipts[this.transactionId]}
            }

            return transactionReceiptData ? transactionReceiptData : null
        },
        transactionSupplierData() {
            let supplierDataFromSuppliersItemsData = null;
            if (this.supplier_items) {
                for (let supplier_type in this.supplier_items) {
                    for (let suppliers_type_item of this.supplier_items[supplier_type]) {
                        if (suppliers_type_item.id == this.transactionSupplierId) {
                            supplierDataFromSuppliersItemsData = {...suppliers_type_item};
                            break;
                        }
                    }
                }
            }

            let supplierDataFromSuppliersData = null;
            if (this.suppliers && this.transactionSupplierId in this.suppliers) {
                supplierDataFromSuppliersData = {...this.suppliers[this.transactionSupplierId]}
            }

            let supplierData = supplierDataFromSuppliersData ? supplierDataFromSuppliersData : supplierDataFromSuppliersItemsData
            return supplierData ? supplierData : null
        },
        transactionProductData() {
            let productDataFromSuppliersData = null;

            if (this.suppliers_products) {
                for (let supplier_id in this.suppliers_products) {
                    for (let supplier_product_type in this.suppliers_products[supplier_id]) {
                        for (let supplier_product of this.suppliers_products[supplier_id][supplier_product_type]) {
                            if (supplier_product.hasOwnProperty('id')
                                && supplier_product.id == this.transactionProductId) {
                                productDataFromSuppliersData = {...supplier_product}
                                break;
                            }
                        }
                    }
                }
            }

            let productDataFromProductsData = null;
            if (this.products && this.transactionProductId in this.products) {
                productDataFromProductsData = {...this.products[this.transactionProductId]}
            }

            let productData = productDataFromProductsData ? productDataFromProductsData : productDataFromSuppliersData
            return productData ? productData : null
        },
        price() {
            return this.transactionReceiptData.price ? this.formatNumber(this.transactionReceiptData.price) : ''
        },
        priceVAT() {
            return this.price ? this.formatNumber(this.price * 0.17) : 0
        },
        priceWithoutVAT() {
            return this.price && this.priceVAT ? this.formatNumber(this.price - this.priceVAT) : this.price
        },
    },
    created() {
        this.getTransactionReceipt({'transaction_id': this.transactionId})

        if (this.transactionProductId) {
            this.getProduct({'product_id': this.transactionProductId})
        }

        if (this.transactionSupplierId) {
            this.getSupplier({'supplier_id': this.transactionSupplierId})
        }
    },
    watch: {},
    methods: {
        ...mapActions('payment', {
            getTransactionReceipt: 'getTransactionReceipt'
        }),
        ...mapActions('product', {
            getProduct: 'getProduct'
        }),
        ...mapActions('supplier', {
            getSupplier: 'getSupplier'
        }),
        getApiURL() {
            return appConfig.apiUrl
        },
        printReceipt() {
            window.print();
        },
        formatNumber(number) {
            let numberFormatObject = new Intl.NumberFormat('en-US')
            return numberFormatObject.format(number)
        }
    },
    updated() {},
}
</script>

<style>
    @media print {
        .blank-aside-js {
            width: 100% !important;
            margin: 0 !important;
        }
        header,
        aside,
        .hide-print {
            display: none !important;
        }
    }
</style>

<style lang="scss" scoped>
.success-section {
    padding: 0 10px;
    max-width: 932px;
    width: 100%;
    margin: 0 auto;
}

.min-w-100px {
    min-width: 100px;
}
</style>